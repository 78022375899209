<template>
  <div>
    <div class="main-loader" v-if="isLoading"></div>
    <div class="feed-page" v-else>
      <b-container>
        <b-row v-if="feedImages.length">
          <image-card
            v-for="image in feedImages"
            :key="image.id"
            :product="image"
            :show-like-button="false"
            :show-share-button="true"
            @toggle-like="handleToggleLike"
          />
        </b-row>

        <div class="no-data mt-5 mb-5" v-else>
          <div class="mx-auto text-center">
            <h2>{{ errorMessage || noDataMessage }}</h2>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import ImageCard from './imageCard.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'FeedPage',
  components: {
    ImageCard,
  },
  data() {
    return {
      noDataMessage: 'Updating latest feed. . .',
    };
  },
  computed: {
    ...mapGetters('app', ['getFeedImages']),
    feedImages() {
      return this.getFeedImages;
    },
    errorMessage() {
      return this.getErrorMessage;
    },
  },
  created() {
    this.fetchFeedImages();
  },
  methods: {
    ...mapActions('app', ['fetchFeedImages', 'updateLikeStatus']),
    handleToggleLike(imageId, currentLikeStatus) {
      const user = JSON.parse(localStorage.getItem('userdetails'));
      const userId = user?.user_id;

      if (!userId) {
        // Prompt user to log in or handle accordingly
        this.$bvModal.show('login-modal');
        return;
      }

      const newLikeStatus = currentLikeStatus === 'Y' ? 'N' : 'Y';

      this.updateLikeStatus({ userId, imageId, likeStatus: newLikeStatus });
    },
  },
};
</script>

<style scoped>
.feed-page {
  padding: 20px 0;
}

.main-loader {
  /* Loader styles */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8) url('/loader.gif') no-repeat center center;
  z-index: 9999;
}
</style>
